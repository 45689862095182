<template>
    <FormTemplate v-if="form" :form="form">
      <div class="form-group">
          <label class="label-for-xl" for="inputName">Domain</label>
          <b-form-input
              type="text" id="inputName" class="form-control form-control-xl"
              placeholder="enter domain name"
              v-model="form.data.domain"
              :state="form.states.domain"
          />
          <b-form-text>
            <strong>Examples:</strong><br>
            <b-row>
              <b-col cols="6">Domain only:</b-col>
              <b-col cols="6">mydomain.com</b-col>
            </b-row>
            <b-row>
              <b-col cols="6">Sub-Domain only:</b-col>
              <b-col cols="6">subdomain.mydomain.com</b-col>
            </b-row>
            <b-row>
              <b-col cols="6">All domains and subdomains:</b-col>
              <b-col cols="6">*.mydomain.com</b-col>
            </b-row>
          </b-form-text>
          <b-form-invalid-feedback v-if="form.errors">{{ form.errors.domain }}</b-form-invalid-feedback>
      </div>
      <template v-slot:buttons>
        <div class="border-top mt-3 pt-3 text-right">
          <button type="submit" class="btn btn-xl btn-primary" :disabled="form.submitButton.disabled">
              <b-spinner small v-if="form.submitButton.disabled"></b-spinner>
              <span class="sr-only" v-if="form.submitButton.disabled">Loading...</span>
              <span  v-if="!form.submitButton.disabled">
                  Create
              </span>
          </button>
        </div>
      </template>
    </FormTemplate>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';

export default {
  props: ['channel'],
  components: {
      FormTemplate
  },
  data(){
      return {
          form: null
      };
  },
  mounted(){
      this.form = new Form({
          domain: "",
      },{
          onSubmit: (form) => {
              return this.$api.post(`channel/${this.channel.id}/origin`, {
                  data: {domain: form.get('domain')}
              })
          },
          onSuccess: (form, {data}) => {
            this.$emit('origin-created', data);
          },
          onError: function(form, err){
              console.log(err);
          }
      });
  }
};
</script>
