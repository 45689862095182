<template>
  <b-card>
    <template #header>
      <b-button variant="primary" size="sm" class="float-right"  v-b-modal.modal-channel-origin>
        <BIconPlus/> Add Origin
      </b-button>
      <b-modal ref="modalChannelOrigin" id="modal-channel-origin" title="Add origin" hide-footer>
          <ChannelOriginForm ref="originForm" @origin-created="originCreated" :channel="channel"/>
      </b-modal>
      <h6 class="mb-0">Channel origins</h6>
      <small class="font-italic">Origins help you control the channel</small>
    </template>
    <b-card-text>
      <div class="content">
        <div v-if="channel.origins && channel.origins.length > 0">
          <table class="table table-hover">
            <thead>
              <tr>
                <th colspan="100%">Domain</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(origin, key) in channel.origins" v-bind:key="key">
                <td>{{origin.domain}}</td>
                <td class="text-right">
                  <b-link @click="deleteOrigin(key)" class="text-danger"><BIconX font-scale="1.5"/></b-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>You dont have any origins set.</div>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {BIconPlus,BIconX} from 'bootstrap-vue';
import ChannelOriginForm from './../../../components/Channel.Origin.Form';
export default {
    props: ['channel'],
    components: {
      ChannelOriginForm,
      BIconPlus,BIconX
    },
    data(){
        return {

        };
    },

    methods: {
      originCreated(channel){
        this.$refs['modalChannelOrigin'].hide();
        this.$emit('on-channel-update', channel);
      },

      deleteOrigin(key){
        let domain = this.channel.origins[key].domain;
        this.$api.delete(`channel/${this.channel.id}/origin`, {
            data: {domain: domain}
        })
        .then((response) => {
          this.$emit('on-channel-update', response.data);
        });
      }
    },

    mounted(){
      this.$emit('set-channel',{activeSidebar: "origins"});
    }
};
</script>
